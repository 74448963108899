import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { onboardUrls, adminUrls, planUrls } from '../global/global';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {
  }
  //sign up
  getSignUp(body): Observable<any> {
    return this.http.post(onboardUrls.simplySignup, body);
  }
  //otp verification
  getOtpVerifyAccount(body): Observable<any> {
    return this.http.post(onboardUrls.simplyVerifyAccount, body);
  }
  //resend otp
  getResendOTP(body): Observable<any> {
    return this.http.post(onboardUrls.simplyResendOtpVerifyAccount, body);
  }
  //forget password
  getForgetPassword(body): Observable<any> {
    return this.http.post(onboardUrls.simplyForgetPassword, body);
  }
  //reset password
  getResetPassword(body): Observable<any> {
    return this.http.post(onboardUrls.simplyResetPassword, body);
  }
  // signin 
  getSignIn(body): Observable<any> {
    return this.http.post(onboardUrls.simplySignIn, body);
  }
  // set admin password
  setAdminPassword(body): Observable<any> {
    return this.http.post(adminUrls.simplyAdminResetPassword, body);
  }
  //notifier checkout url
  getNotifierCheckout(): Observable<any> {
    return this.http.post(planUrls.NotifierCheckout, '');
  }
}
